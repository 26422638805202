@import "main.css";
.websitePageTitle-div {
display : none !important;
}

@media all and (min-width: 990px) {
.redirectsCards-div2 div.row-cols-1 >div.col {
width : 25% !important;
}
}

@media all and (min-width: 990px) {
.redirectsCards-div div.row-cols-1 >div.col {
width : 33% !important;
}
}

/*Effet sur les prestations de la home page*/ 

div.redirectsCards-div {     
margin: 0 !important;     
padding: 0 !important; 
} 

div.redirectsCards-div >.row {     
margin: 0 !important;     
padding: 0 !important; 
} 

div.redirectsCards-div >.row > .col {     
margin: 0 !important;     
padding: 0 !important; 
filter: grayscale(1); 
} 

div.redirectsCards-div >.row > .col:hover {     
filter: none; 
transition: all 0.3s cubic-bezier(.86, .01, .15, .99);
}



div.redirectsCards-div2 {     
margin: 0 !important;     
padding: 0 !important; 
} 

div.redirectsCards-div2 >.row {     
margin: 0 !important;     
padding: 0 !important; 
} 

div.redirectsCards-div2 >.row > .col {     
margin: 0 !important;     
padding: 0 !important; 
filter: grayscale(1); 
} 

div.redirectsCards-div2 >.row > .col:hover {     
filter: none; 
transition: all 0.3s cubic-bezier(.86, .01, .15, .99);
}

/*-------------Boutons en bas à droite------------------*/

.btnDevis {

position: fixed;

bottom: 20px; right: 0;

font-size: 18px;

padding: 10px 20px;

background: #B28F45;

color: white;

text-decoration: none;

border-radius : 30px 0 0 30px !important;

z-index: 10;

font-family: 'Roboto';

}

/* Style HOVER*/
\

.btnDevis:hover {

background: #B28F45;

color: white;

}

.divBtnRDV {

text-align : center;

margin-bottom: 90px;

}

/* Style normal*/
.btnRDV {

font-family: 'Roboto';

font-size: 24px;

padding: 10px 20px;

background: #B28F45;

color: white;

text-decoration: none;

border-radius : 20px;

}

/* Style HOVER*/
.btnRDV:hover {

background: #B28F45;

color : black;

transition: all 0.4s cubic-bezier(.86, .01, .15, .99);

}

.redirects-title-background.position-relative {
    margin-top: 14rem;
}

.redirects-title-background.positive-relative {
    margin-top: 14rem;
}


.titleText.text-white.text-center.fs-2.mb-4.mt-4.titles {
    margin-bottom: 10rem !important;
}

/*------------Fixer le header sur toutes les pages-----------------*/

#navigation-bar-hide {
opacity: 1 !important;
top: 0 !important;
}

/*------------Mise en forme des tableaux-----------------*/

table th {
    background-color: transparent !important;
    border: none !important;
    border-bottom-color: transparent !important;
}

table td {
    border: none !important;
}

table tr:nth-child(even) {
    background-color: transparent !important;
}

table {
border: none !important;
}

#tableau + .cta_banner .ctaLinks-button {
    display: none;
}


/* Par défaut, le tableau s'affiche normalement sur les grands écrans */
.col-lg-6 .lead table {
    width: auto !important; /* Garde la largeur actuelle sur les grands écrans */
}

/* Sur les écrans plus petits (mobile), le tableau devient défilable */
@media screen and (max-width: 768px) {
    .col-lg-6 .lead {
        overflow-x: auto; /* Active le défilement horizontal */
    }

    .col-lg-6 .lead table {
        min-width: 841px; /* Définit une largeur minimale pour le tableau */
        display: block; /* Rend le tableau compatible avec le défilement */
    }
}


.px-2.px-lg-4.py-2 {
    display: none;
}

a.nav-link.fs-6.dropdown-toggle.px-0.text-color-secondary.text-center {
    color: #b28f45 !important;
}